import { template as template_0a65c9ed74944dc5b808299610413457 } from "@ember/template-compiler";
const FKLabel = template_0a65c9ed74944dc5b808299610413457(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
